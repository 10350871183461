import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { PREMIUM_SUBSCRIPTION_STRIPE_PRODUCT_ID } from '../../constants';
import { useUserProfile } from '../../core/TTgraphql';
import createPremiumPlusSubscription from '../../graphql/mutations/payments/createPremiumPlusSubscription.graphql';
import { getCookies } from '../../helpers/cookies';
import { createAndSaveIdempotencyKey } from '../../helpers/createAndSaveIdempotencyKey';
import getPremiumPlanId from '../../helpers/getPremiumPlanId';
import getUserPremiumPrice from '../../helpers/getUserPremiumPrice';
import { useRenderOutside } from '../../helpers/render-outside';
import { resetIdempotencyKey } from '../../helpers/resetIdempotencyKey';
import withBillingPlansTest from '../../hoc/withBillingPlansTest';
import StripeElementsModal from '../../pages/common/stripe/StripeElementsModal';
import { segmentTracking } from '../../services/utilities/segment';
import { useErrorToast } from '../Toast';

import { MODAL_LOCATIONS } from './constants';
import getSegmentLocation from './get-premium-modal-segment-location';
import getPremiumPurchasedFrom from './getPremiumPurchasedFrom';
import PremiumIsActiveModal from './PremiumIsActivateModal';
import PremiumSubscriptionModalOdd from './PremiumSubscriptionModalOdd';

const PremiumSubscriptionModalWrapped = ({
  onClose,
  singlePaymentType,
  singlePaymentAction,
  onSuccess,
  refetchQueries,
  postPaymentAction,
  showFormsLink,
  hideButtons,
  benefitsType,
  openLocation,
  copy,
  segmentProperties = {},
  purchase_location,
  extraParams = {},
  onSubscribeClicked,
  onPayClicked,
}) => {
  const [showStripe, setShowStripe] = useState(false);
  const errorToast = useErrorToast();
  const { user } = useUserProfile({}, false);
  const premiumPlanPrice = getUserPremiumPrice(user);
  const planId = getPremiumPlanId(user);
  const renderOutside = useRenderOutside();

  const queryParams = queryString.parse(location.search);
  const isMobileApp = queryParams?.mobile_app === 'true';
  const modalTypeDesktop = openLocation || singlePaymentType;
  const modalType = isMobileApp ? MODAL_LOCATIONS.MOBILE_APP : modalTypeDesktop;
  const { premium_referral } = getCookies();

  useEffect(() => {
    /**
     * NOTE: We're loading the modal without the buttons only
     * on Lease Agreement Wizard Page for now, if this changes,
     * we need to make this `location` dynamic
     */
    if (singlePaymentType === MODAL_LOCATIONS.LEASE) {
      segmentTracking('subscription_modal loaded', {
        location: 'Lease Agreement Wizard Page',
        ...segmentProperties,
      });
    } else {
      segmentTracking('subscription_modal loaded', {
        location: getSegmentLocation(modalType),
        ...segmentProperties,
      });
    }
  }, []);

  const [createSubscription] = useMutation(createPremiumPlusSubscription, {
    refetchQueries: refetchQueries?.length
      ? refetchQueries.map((query) => ({ query }))
      : null,
    awaitRefetchQueries: true,
  });

  const onToken = useCallback(async (token, { promoCode }) => {
    let error;
    try {
      const idempotencyKey = createAndSaveIdempotencyKey();
      await createSubscription({
        variables: {
          token,
          promoCode,
          idempotencyKey,
          plan_id: planId,
          // If not referred by any source, we track the location from where it is being bought
          referral: premium_referral || getPremiumPurchasedFrom(modalType),
          location: purchase_location,
        },
      });

      segmentTracking('purchase success', {
        location: getSegmentLocation(modalType),
        ...segmentProperties,
      });

      renderOutside((done) => (
        <PremiumIsActiveModal
          open={done}
          onClose={() => {
            done();
          }}
          segmentLocation={getSegmentLocation(modalType)}
          segmentProperties={{
            ...segmentProperties,
          }}
          segmentLoadEvent={'premium_success_modal loaded'}
          showExpirationDate
        />
      ));

      if (postPaymentAction) {
        await postPaymentAction();
      }
      onSuccess();
    } catch (e) {
      const message = get(e, 'graphQLErrors[0].message', 'An error occurred');
      errorToast(message);
      error = e?.graphQLErrors;
    }

    resetIdempotencyKey(error);
  }, []);

  if (!user?.id) {
    return null;
  }

  const getPremiumClicked = () => {
    setShowStripe(true);
  };

  const stripeModalLabel = `Subscribe to Premium`;

  const supportedStripeProductIds = [PREMIUM_SUBSCRIPTION_STRIPE_PRODUCT_ID];

  if (showStripe) {
    return (
      <StripeElementsModal
        open
        onClose={() => {
          setShowStripe(false);
          onClose();
        }}
        onToken={onToken}
        title={stripeModalLabel}
        amount={premiumPlanPrice}
        buttonLabel={`Pay $${premiumPlanPrice}`}
        enablePromo
        supportedStripeProductIds={supportedStripeProductIds}
        amountLabel="year"
        onPayClicked={() => {
          segmentTracking('get_premium_purchased clicked', {
            location: getSegmentLocation(modalType),
            ...segmentProperties,
          });

          if (onPayClicked) onPayClicked();
        }}
      />
    );
  } else {
    return (
      <PremiumSubscriptionModalOdd
        {...{
          singlePaymentType,
          singlePaymentAction,
          onClose,
          showFormsLink,
          openLocation,
          copy,
          extraParams,
        }}
        isMobileApp={isMobileApp}
        hideButtons={hideButtons}
        onSubscribe={() => {
          onSubscribeClicked ? onSubscribeClicked() : getPremiumClicked();
        }}
        benefitsType={benefitsType}
        premiumPlanPrice={premiumPlanPrice}
        segmentProperties={{ ...segmentProperties }}
        user={user}
      />
    );
  }
};

PremiumSubscriptionModalWrapped.propTypes = {
  onClose: PropTypes.func,
  singlePaymentType: PropTypes.oneOf([
    MODAL_LOCATIONS.FORMS,
    MODAL_LOCATIONS.LEASE,
    MODAL_LOCATIONS.ADDENDUM,
    MODAL_LOCATIONS.E_SIGN,
  ]),
  singlePaymentAction: PropTypes.func,
  onSuccess: PropTypes.func,
  refetchQueries: PropTypes.array,
  postPaymentAction: PropTypes.func,
  showFormsLink: PropTypes.bool,
  hideButtons: PropTypes.bool,
  benefitsType: PropTypes.string,
  openLocation: PropTypes.oneOf(Object.keys(MODAL_LOCATIONS)),
  copy: PropTypes.oneOf([
    'rentPayments',
    'multipleBankAccounts',
    'forms',
    'support',
    'generic',
    'marketing',
    'incomeinsights',
    'inviteToApply',
  ]),
  segmentProperties: PropTypes.object,
  onSuccessModalDoneClicked: PropTypes.func,
  purchase_location: PropTypes.string,
  extraParams: PropTypes.object,
  onSubscribeClicked: PropTypes.func,
  onPayClicked: PropTypes.func,
};

// We are currently testing offering multiple billing plans to users. This HOC is used to test the new billing plans.
// And for users affected by this tests PremiumSubscriptionModalWrapped will not be rendered. Instead this will render BillingPlans component. */
export default withBillingPlansTest(PremiumSubscriptionModalWrapped);
