export const Experiments = {
  RenterCreditReportingFlow: {
    name: 'renter_credit_reporting_flow',
    variants: {
      A: 'variant_A',
      B: 'variant_B',
    },
  },
  GoodBetterBest: {
    name: 'experiment_gbb',
    variants: {
      A: 'A', // Tabs
      B: 'B', // Accordion
      C: 'C', // Toggle
      control: 'Control',
    },
  },
  LandlordPaysSpecificStates: {
    name: 'LL_Pays_NY_WI_MA_VT',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  LandlordPaysOtherStates: {
    name: 'LL_Pays_Other_States',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  MaintenanceRequestLulaProCardAug2024: {
    name: 'maintenance_request_lula_pro_card_aug_2024',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  ReiHubAddonOffer: {
    name: 'rei_addon_offer',
    variants: {
      quarterly: 'quarterly',
      annually: 'annually',
    },
  },
  ReiHubMonthlyTrialOffer: {
    name: 'rei_monthly_trial_offer',
    variants: {
      test: 'free_trial',
      control: '50_percent_off',
    },
  },
};
