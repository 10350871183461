import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const CheckmarkFilled = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 24" {...rest} fill={color}>
    <path d="M12 2c5.522 0 10 4.477 10 10s-4.478 10-10 10C6.476 22 2 17.524 2 12S6.476 2 12 2Zm5.207 6.293a1 1 0 0 0-1.414 0L10.5 13.585l-2.293-2.292-.094-.083a1 1 0 0 0-1.32 1.497l2.823 2.823.102.091a1.25 1.25 0 0 0 1.666-.09l5.823-5.824.083-.094a1 1 0 0 0-.083-1.32Z" />
  </svg>
);

CheckmarkFilled.defaultProps = {
  width: 24,
  height: 24,
  color: colors.orangeLight,
};

CheckmarkFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CheckmarkFilled);
